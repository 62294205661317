var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.createSpaceAndApp.operationSelected
    ? _c(
        "v-container",
        { staticClass: "pb-0", staticStyle: { "max-width": "700px" } },
        [
          _c(
            "v-stepper",
            { attrs: { value: _vm.createSpaceAndApp.step } },
            [
              _c(
                "v-stepper-header",
                [
                  _c(
                    "v-stepper-step",
                    {
                      attrs: {
                        color: "primary",
                        step: "1",
                        complete: _vm.createSpaceAndApp.step > 1,
                      },
                    },
                    [_vm._v("Create Space")]
                  ),
                  _c("v-divider"),
                  _c(
                    "v-stepper-step",
                    {
                      attrs: {
                        color: "primary",
                        step: "2",
                        complete: _vm.createSpaceAndApp.step > 2,
                      },
                    },
                    [
                      _vm._v(" Add Application "),
                      _vm.createSpaceAndApp.skipAppCreation
                        ? _c("small", [_vm._v("skipped")])
                        : _vm._e(),
                    ]
                  ),
                  _c("v-divider"),
                  _c(
                    "v-stepper-step",
                    {
                      attrs: {
                        color: "primary",
                        step: "3",
                        complete: _vm.createSpaceAndApp.lastStepCompleted,
                      },
                    },
                    [_vm._v("Get Started")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }